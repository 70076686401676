import Image from 'next/image';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Block, Box, ButtonLink, createComponent, Flex, For, If, IntrinsicProps } from 'react-commons';
import animate from 'animejs';

import { FeaturedGameData } from '@/lib/drupal/models/Games';
import Link from 'next/link';
import GamepadIcon from '@/components/GamepadIcon';
import GameTileImg from '../GameTileImg';
import { UpsellPodText } from '@/components/UpsellPod';

import style from './index.module.scss';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';

const THUMBNAIL_HEIGHT = 64;
const TRANSITION_INTERVAL = 5000;
const UPSELL_TITLE = 'Shockwave Unlimited';
const UPSELL_DESCRIPTION = 'Upgrade to Shockwave Unlimited to access our vast library of Premium Games and an ad free experience.';
const UPSELL_THUMBNAIL_SRC = '/images/upsellSlideThumb.png';
const UPSELL_FEATURED_BG_SRC = '/images/upsellSlideBg.jpg';

interface UpsellSlideData extends Partial<FeaturedGameData> {
  isUpsell?: boolean
}

export const BasicUpsellSlide = createComponent('BasicUpsellSlide', { style }, function BasicUpsellSlide ({ className }) {
  return (
    <figure className={className}>
      <Image 
        alt='Background image'
        src={UPSELL_FEATURED_BG_SRC}
        layout='fill'
        objectFit='cover'
        objectPosition='center'
      />
      <Block>
        <Link href='/unlimited'>
          <a>
            <Image
              src='/images/shockwaveUnlimited.svg'
              alt='Shockwave.com'
              width={218}
              height={76}
            />
          </a>
        </Link>
      </Block>
      <Box>
        <p>Play all you want for less than</p>
        <p><em>$5 monthly!</em></p>
      </Box>
      <ButtonLink href='/unlimited' secondary medium>
        Learn More
      </ButtonLink>
    </figure>
  );
});

export const CharacterUpsellSlide = createComponent('CharacterUpsellSlide', { style }, function CharacterUpsellSlide ({ className }) {
  return (
    <Link href='/unlimited'>
      <figure className={className} style={{ backgroundImage: `url(${UPSELL_FEATURED_BG_SRC})` }}>
        <Image 
          alt='Background image'
          src={UPSELL_FEATURED_BG_SRC}
          layout='fill'
          objectFit='cover'
          objectPosition='center'
        />
        <div>
          <Block>
            <Link href='/unlimited'>
              <a>
                <Image
                  src='/images/shockwaveUnlimited.svg'
                  alt='Shockwave.com'
                  width={218}
                  height={76}
                />
              </a>
            </Link>
          </Block>
          <Box>
            <UpsellPodText />
          </Box>
        </div>
        <aside>
          <Image
            src='/images/unlimited/characters/goldielocks.png'
            alt='Goldielocks'
            width={606}
            height={1174}
          />
        </aside>
        <aside>
          <Image
            src='/images/unlimited/characters/lady.png'
            alt='Dark Lady'
            width={386}
            height={1044}
          />
        </aside>
      </figure>
    </Link>
  );
});

export interface FeaturedCarouselProps extends IntrinsicProps {
  games: [
    FeaturedGameData, 
    FeaturedGameData, 
    FeaturedGameData,
    FeaturedGameData?
  ]
  useUpsellSlide?: false | ReactNode
  upsellSlidePosition?: 0 | 1 | 2 | 3
}

export default createComponent<FeaturedCarouselProps>('FeaturedCarousel', { style }, function FeaturedCarousel ({ className }, props) {
  const games = props.useUpsellSlide
    ? props.games.slice(0, 3)
    : props.games.slice(0, 4);

  const gameSlides: UpsellSlideData[] = [ ...games ];
  if (props.useUpsellSlide) {
    gameSlides.splice(props.upsellSlidePosition, 0, { isUpsell: true });
  }

  const [ isLoading, setIsLoading ] = useState(false);
  const [ description, setDescription ] = useState([ 
    games[ 0 ].title, 
    games[ 0 ].description,
    games[ 0 ].href,
  ]);

  const featuredElRef = useRef<HTMLDivElement | null>(null);
  const curIndexRef = useRef(0);
  const pipElRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const pauseTimer = () => {
    clearTimeout(timerRef.current);
  };

  const restartTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const nextIndex = (curIndexRef.current + 1) % 4;
      showSlide(nextIndex);
      restartTimer();
    }, TRANSITION_INTERVAL);
  };

  const positionPip = async (toIndex: number) => {
    const containerEl = pipElRef.current.closest('.Flex');

    const targetEl = containerEl.querySelectorAll('figure')[ toIndex ];
    const targetY = targetEl.offsetTop + (THUMBNAIL_HEIGHT / 2) + 'px';

    await animate({
      targets: [ pipElRef.current ],
      easing: 'easeInOutCubic',
      opacity: [ 1, 1 ],
      translateY: targetY,
      duration: 500,
    }).finished;
  };

  const showSlide = async (nextIndex: number) => {
    if (!featuredElRef.current) {
      pauseTimer();
      return;
    }

    if (isLoading) return;

    const curIndex = curIndexRef.current;
    if (curIndex === nextIndex) return;

    pauseTimer();
    setIsLoading(true);

    curIndexRef.current = nextIndex;

    const els = featuredElRef.current.querySelectorAll('figure');
    const lastEl = els[ curIndex ];
    const nextEl = els[ nextIndex ];

    const direction = nextIndex > curIndex
      ? 'next'
      : 'previous';

    positionPip(nextIndex);

    if (props.useUpsellSlide && nextIndex === props.upsellSlidePosition) {
      setDescription([ 
        UPSELL_TITLE, 
        UPSELL_DESCRIPTION,
        '/unlimited'
      ]);
    } else {
      setDescription([ 
        gameSlides[ nextIndex ].title, 
        gameSlides[ nextIndex ].description,
        gameSlides[ nextIndex ].href,
      ]);
    }

    lastEl.style.zIndex = '0';
    lastEl.style.pointerEvents = 'none';
    lastEl.classList.remove('--slideIn');
    lastEl.classList.remove('--slideInBefore');
    lastEl.classList.add('--slideOut');

    nextEl.style.zIndex = '1';
    nextEl.style.pointerEvents = 'auto';
    nextEl.classList.remove('--slideIn');
    nextEl.classList.remove('--slideOut');
    nextEl.classList.add('--slideInBefore');
    
    await Promise.all([
      animate({
        targets: [ lastEl ],
        easing: 'easeInOutCubic',
        opacity: [ 1, 1 ],
        translateY: direction === 'next' 
          ? [ '0%', '-100%' ]
          : [ '0%', '100%' ],
        duration: 500,
      }).finished,
      animate({
        targets: [ nextEl ],
        easing: 'easeInOutCubic',
        opacity: [ 1, 1 ],
        translateY: direction === 'next' 
          ? [ '100%', '0%' ]
          : [ '-100%', '0%' ],
        duration: 500,
      }).finished
    ]);

    lastEl.classList.remove('--slideOut');

    nextEl.classList.remove('--slideInBefore');
    nextEl.classList.add('--slideIn');

    setIsLoading(false);
  };

  useEffect(() => {
    if (timerRef.current !== null) return;
    restartTimer();
  });

  useEffect(() => {
    const onFocusHandler = () => restartTimer();
    window.addEventListener('focus', onFocusHandler);

    const onBlurHandler = () => pauseTimer();
    window.addEventListener('blur', onBlurHandler);

    return () => {
      window.removeEventListener('focus', onFocusHandler);
      window.removeEventListener('blur', onBlurHandler);
    };
  });

  const captureClickEventContext = useContext(CaptureClickEventContext);
  const titleAction = captureClickEventContext.settings.upsellFeaturedGameEvent || 'upsell_generic_featured_game';
  const titleLocation = captureClickEventContext.settings.upsellFeaturedGameLocation || 'featured_game_panel_generic';

  return (
    <div 
      className={className}
      onMouseEnter={pauseTimer}
      onMouseLeave={restartTimer}
    >
      <Flex alignCenter>

        <div className='FeaturedCarousel__FeatImg' ref={featuredElRef}>
          {
            For(gameSlides, (game, index: number) => (
              <React.Fragment key={index}>
                {
                  If(game.isUpsell, () => (
                    <>{props.useUpsellSlide}</>
                  ))
                    .Else(() => (
                      <figure>
                        <Link href={game.href}>
                          <a>
                            <Image
                              src={game.featuredImg}
                              alt={game.title}
                              width={516}
                              height={282}
                            />
                          </a>
                        </Link>
                        <ButtonLink href={game.href} secondary small>
                          Play Now
                          <GamepadIcon />
                        </ButtonLink>
                      </figure>
                    ))
                    .EndIf()
                }
              </React.Fragment>
            ))
          }
        </div>

        <Flex fit directionColumn justifyCenter>
          <div 
            ref={pipElRef} 
            className='FeaturedCarousel__Pip'
            style={{ transform: 'translateY(36px)' }}
          />
          {
            For(gameSlides, (slide, index: number) => (
              <React.Fragment key={index}>
                {
                  If(slide.isUpsell, () => (
                    <GameTileImg
                      src={UPSELL_THUMBNAIL_SRC} 
                      title={UPSELL_TITLE}          
                      small
                      onClick={() => showSlide(index)}
                    />
                  ))
                    .Else(() => (
                      <GameTileImg
                        key={index}
                        src={slide.thumbnailImgSmall}
                        title={slide.title}          
                        small
                        onClick={() => showSlide(index)}
                      />
                    ))
                    .EndIf()
                }
              </React.Fragment>
            ))
          }
        </Flex>

      </Flex>

      <div className='FeaturedCarousel__Desc'>
        <div>
          <p>
            <CaptureClickEvent
              action={titleAction}
              location={titleLocation}
              className={`${titleAction}_desc`}
              disabled={(_el, href) => {
                return href !== '/unlimited';
              }}
            >
              <Link href={description[ 2 ]}>
                <a className={`${titleAction}_desc`}><strong>{description[ 0 ]}</strong></a>
              </Link>
            </CaptureClickEvent>
          </p>
          <p><small>{description[ 1 ]}</small></p>
        </div>
      </div>
    </div>
  );
});
